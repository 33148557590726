<template>
	<div id="About">
		<MyHeaderAbout />
		<div class="about_us">
			<h3>About us</h3>
			<p>Since its establishment in 1988, Moona has grown to be one of the leading manufacturer and exporter of
				wood shutters
				& shutter components in China.
				Moona Shutter is a number of Moona Group, which has four parts: Hengda Wood, Sennan Wood, Moona Shutter,
				Moona Furniture, 
				owning factories about 165,000M2 and construction lands 92,000M2 and 1000 workers.
				With premium quality, innovation, and sustainability our shutters and shutter components got welcomed in
				Europe,
				North America, Australia and many other areas. </p>
			<img src="@/assets/images/home-07.png" />
			<router-link class="read-more" to="/TradeArea">Read More &gt;&gt;</router-link>
		</div>
		<div class="video-content">
			<video ref="videoPlayer" id="startvideo" width="1360" height="480" controls muted="muted">
				<source src="https://moonashutters.com/media/video.055d7ec3.mp4" type="video/mp4">
				<!-- <source src="https://youtu.be/Kg5-ZkvwueA" type="video/mp4"> -->
				<!-- <source :src="videoUrl" type="video/mp4"> -->
				您的浏览器不支持Video标签。
			</video>
		</div>
		<MyFooter />
	</div>
</template>
<script>
	import * as Api from '@pm'
	import MyHeaderAbout from '@/components/MyHeaderAbout.vue'
	import MyFooter from '@/components/MyFooter.vue'
	export default {
		name: 'About',
		components: {
			MyHeaderAbout,
			MyFooter
		},
		data() {
			return {
				ruleForm: {
					name: '',
					email: '',
					phone: '',
					message: ''
				},
				rules: {
					name: [{
							required: true,
							message: 'Please enter your name',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 5,
							message: '3 to 5 characters in length',
							trigger: 'blur'
						}
					],
					email: [{
						required: true,
						message: 'Please fill in the email address',
						trigger: 'blur',
						type: 'email'
					}],
					phone: [{
						required: true,
						message: 'Please fill in your mobile number',
						trigger: 'blur'
					}],
					message: [{
						required: true,
						message: 'Please fill in the information',
						trigger: 'blur'
					}]
				},
				center: [115.224203, 35.03307], //地图中心点坐标
				zoom: 20, //初始化地图显示层级
				mapStyle: 'amap://styles/8b6be8ec497009e17a708205348b899a', //设置地图样式
				icon: '',
				productList: [],
				videoUrl: require('@/assets/video.mp4')
			}
		},
		created() {
			// this.getImages()
			this.$nextTick(() => {
				this.$refs.videoPlayer.play();
				var video = document.getElementById("startvideo");
				//调用video标签的播放函数
				video.play();
				this.$nextTick(() => {
					this.$refs.videoPlayer.muted = false;
				},2000)
			})
		},
		methods: {
			getImages() {
				Api.getPIndexImages(this.ruleForm).then((result) => {
					if (result.data.code == 200) {
						let _res = result.data.data || []
						this.productList = _res.slice(0, 6)
					} else {
						return false
					}
				})
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true,
							text: 'Loading',
							spinner: 'el-icon-loading',
							background: 'rgba(0, 0, 0, 0.7)'
						})
						Api.sendEmail(this.ruleForm).then((result) => {
							loading.close()
							if (result.data.code == 200) {
								this.$refs[formName].resetFields()
								alert('submit success!')
							} else {
								alert(result.data.msg)
								return false
							}
						})
					} else {
						console.log('error submit!!')
						return false
					}
				})
			}
		}
	}
</script>
<style lang="less" scoped>
	#About {
		.block1 {
			.block1_title {
				width: 743px;
				height: 171px;
				margin: 30px auto;

				p {
					font-size: 60px;
					font-family: Yuanti SC;
					font-weight: bold;
					line-height: 99px;
					color: #7ec55a;

					&:first-child {
						float: left;
					}

					&:nth-child(2) {
						float: right;
					}
				}
			}

			.block1_img {
				width: 1680px;
				margin: 90px auto 90px;
				display: flex;
				flex-wrap: wrap;

				span {
					display: inline-block;
					margin-left: 172px;
					margin-bottom: 135px;

					&:nth-child(3n + 1) {
						margin-left: 0;
					}

					img {
						width: 445px;
						height: 460px;
					}
				}
			}
		}

		.about_us {
			width: 1440px;
			margin: 80px auto 0;
			// height: 805px;
			// background: rgba(242, 241, 241, 1);
			padding: 40px 40px 30px;
			position: relative;

			h3 {
				font-size: 58px;
				font-weight: normal;
				font-family: PingFangSC-Semibold;
				color: #111815;
			}

			p {
				font-size: 20px;
				line-height: 52px;
				font-family: DINNextLTPro-Regular;
				color: #676767;
			}

			img {
				width: 1360px;
				height: 360px;
				margin-top: 30px;
			}

			.read-more {
				font-size: 30px;
				font-family: DINNextLTPro-Regular;
				line-height: 30px;
				color: #070707;
				text-decoration: underline;
				position: absolute;
				bottom: 410px;
				right: 40px;
			}
		}

		.video-content {
			width: 1360px;
			margin: 0 auto;
			border: 1px solid #eee;
		}

		#information {
			// width: 100%;
			// min-width: 1440px;
			width: 1440px;
			margin: 0 auto;
			margin-top: 53px;
			margin-bottom: 53px;
			height: 337px;
			overflow: hidden;

			.bg {
				// width: 100%;
				// min-width: 1440px;
				width: 1440px;
				height: 337px;
				overflow: hidden;
				position: absolute;
				margin: 0 auto;
				z-index: -1;

				.bg_left {
					position: absolute;
					width: 45%;
					height: 100%;
					background: rgba(33, 32, 48, 0.9);
					z-index: 1;
				}

				.bg_triangle-topleft {
					position: absolute;
					left: 45%;
					width: 0;
					height: 0;
					border-top: 952px solid rgba(33, 32, 48, 0.9);
					border-right: 200px solid transparent;
					z-index: 2;
				}

				.bg_right {
					position: absolute;
					width: 100%;
					height: 100%;
					background: #212030;
				}
			}

			.content {
				h2 {
					font-size: 35px;
					font-family: DINNextLTPro-Bold;
					line-height: 38px;
					color: #ffffff;
					font-weight: 100;
					margin-bottom: 10px;
				}

				.width578 {
					width: 520px;
					height: 38px;
					line-height: 38px;
					// height: 76px;
					border: 1px solid #ffffff;
					border-radius: 4px;
					position: relative;
					padding-left: 10px;
					color: #ffffff;
					font-size: 24px;

					&::before {
						content: '*';
						position: absolute;
						left: 10px;
						top: 0;
						color: #ff0000;
					}

					&.textarea {
						height: auto;
					}
				}
			}

			.submit-btn {
				width: 212px;
				height: 47px;
				border-radius: 8px;
				background: rgba(149, 236, 105, 1);
				color: #fff;
				font-size: 28px;
				font-family: DINNextLTPro-Regular;
				color: #ffffff;
				outline: none;
				box-shadow: none;
				border: none;
			}
		}
	}
</style>
<style lang="less">
	.demo-ruleForm-about {
		.el-form-item {
			margin-bottom: 10px;
			width: 520px;
		}

		.el-form-item__content {
			line-height: 32px;
		}

		.el-form-item__error {
			top: 6px;
			left: auto;
			right: 20px;
			font-size: 20px;
		}

		.textarea+.el-form-item__error {
			top: 6px;
		}

		.el-input__inner {
			height: 100%;
			line-height: 100%;
			background-color: transparent;
			border: none;
		}

		.el-textarea__inner {
			height: 64px !important;
			border: none;
		}

		.el-input__inner,
		.el-textarea__inner {
			background-color: transparent;
			outline: none;
			box-shadow: none;
			color: #ffffff;
		}
	}
</style>