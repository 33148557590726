<template>
  <!-- <div v-bind:class="{ active: isActive, 'size-30': isSuccess }">根据property: 'isActive'动态切换'active'样式是否显示</div> -->

  <!-- <div id="nav" :class="[hasLinkBtn ? 'IndexAbout' : '', isGallery ? 'PageGallery' : '']"> -->
  <div id="navAbout" :class="{'IndexAbout': hasLinkBtn, 'PageGallery' : isGallery}">
    <div class="nav_block">
      <div class="left">
        <img src="@/assets/ico.png">
      </div>
      <div class="right">
        <ul>
          <li v-for="(item,i) in list" :key="i">
            <router-link :to="item.url" :class="url==item.url ? 'font active' : 'font'">{{item.name}}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="carouselbox" id="carouselId">
      <div class="swiper-container" id="mySwiperId" ref="mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) of productList" :key="index">
            <img :src="item.imagesUrl">
          </div>
        </div>
      </div>
      <div class="swiper-button-prev" @click="prev"></div>
      <div class="swiper-button-next" @click="next"></div>
      <div class="swiper-pagination"></div>
    </div>
    <router-link v-if="hasLinkBtn" class="link-btn shutters-btn" to="/Shutters">Learn More About Shutters</router-link>
    <router-link v-if="hasLinkBtn" class="link-btn mooona-btn" to="/TradeArea?hashName=AboutMoona">Learn More About Moona</router-link>
  </div>
</template>
<script>
import Swiper from 'swiper'
export default {
  name: 'MyHeaderAbout',
  data() {
    return {
      hasLinkBtn: false,
      isGallery: false,
      list: [
        {
          name: 'ABOUT',
          url: '/About',
          font: 'font3'
        },
        {
          name: 'PRODUCT RANGE',
          url: '/Shutters',
          font: 'font3'
        },
        {
          name: 'SHUTTER COMPONENTS',
          url: '/ShutterComponents',
          font: 'font2'
        },
        {
          name: 'GALLERY',
          url: '/Gallery',
          font: 'font2'
        },
        // {
        //   name: 'Trade Area',
        //   url: '/TradeArea',
        //   font: 'font1'
        // },
        {
          name: 'CONTACT',
          url: '/Contact',
          font: 'font1'
        }
      ],
      productList: [
        { name: 1, imagesUrl: require('@/assets/images/banner-01.png') },
        { name: 2, imagesUrl: require('@/assets/images/banner-02.png') },
        { name: 3, imagesUrl: require('@/assets/images/banner-03.png') },
        { name: 4, imagesUrl: require('@/assets/images/banner-04.png') },
        { name: 5, imagesUrl: require('@/assets/images/banner-05.png') }
      ],
      screenWidth: document.body.clientWidth
    }
  },
  computed: {
    url() {
      this.hasLinkBtn = false
      this.isGallery = false
      let router_path = this.$route.path
      if (router_path === '/About') {
        this.hasLinkBtn = true
      } else if (router_path === '/Gallery') {
        this.isGallery = true
      } else {
        this.hasLinkBtn = false
      }
      return router_path
    }
  },
  mounted: function () {
    new Swiper('.swiper-container', {
      loop: true,
      autoplay: {
        disableOnInteraction: false,
        delay: 5000
      },
      speed: 1500,
      effect: 'fade',
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        disabledClass: 'my-button-disabled'
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      }
    })
    const that = this
    window.screenWidth = document.body.clientWidth
    that.screenWidth = window.screenWidth
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth
        that.screenWidth = window.screenWidth
      })()
    }
  },
  watch: {
    /* 监听*/
    screenWidth: {
      handler: function (val) {
        this.screenWidth = val
        console.log('this.screenWidth', this.screenWidth)
        if (this.screenWidth > 1440) {
          document.getElementById('navAbout').style.height = this.screenWidth / 1.7778 + 'px'
          document.getElementById('carouselId').style.height = this.screenWidth / 1.7778 + 'px'
          document.getElementById('mySwiperId').style.height = this.screenWidth / 1.7778 + 'px'
        }
      }
      // immediate: true
    }
  },
  methods: {
    prev() {
      this.$refs.mySwiper.swiper.slidePrev()
    },
    next() {
      this.$refs.mySwiper.swiper.slideNext()
    }
  }
}
</script>
<style lang="less" scoped>
#navAbout {
  position: relative;
  width: 100%;
  height: 810px;
  // background-image: url('../assets/images/home-02.png');
  // background-size: 100% 100%;
  // &.IndexAbout {
  //   background: none;
  // }
  // &.PageGallery {
  //   background-image: url('../assets/images/gallery-banner.png');
  // }
  .nav_block {
    width: 1440px;
    height: 105px;
    line-height: 105px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
    .left {
      width: 386px;
      img {
        width: 386px;
        height: 105px;
        float: left;
        margin-top: 12px;
      }
    }
    .right {
      ul {
        display: flex;
        align-items: center;
        li {
          padding: 0 25px;
          a {
            font-size: 20px;
            font-family: STHeiti TC;
            color: #515151;
            white-space: nowrap;
            &.active {
              color: #070707;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
  .link-btn {
    display: block;
    position: absolute;
    bottom: 28px;
    height: 60px;
    line-height: 60px;
    background: rgba(216, 216, 216, 0.39);
    border-radius: 15px;
    font-size: 29px;
    font-family: Yuanti SC;
    font-weight: bold;
    color: #000000;
    padding: 0 20px;
    z-index: 11;
    &.shutters-btn {
      left: 90px;
    }
    &.mooona-btn {
      right: 90px;
    }
  }
}
.carouselbox,
.swiper-container {
  height: 810px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
}
::v-deep .swiper-pagination {
  bottom: 20px;
  left: 0;
  right: 0;
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    margin: 0 5px;
  }
}
</style>